import { render, staticRenderFns } from "./Withdraw.vue?vue&type=template&id=4638c1ac&scoped=true"
import script from "./Withdraw.vue?vue&type=script&lang=js"
export * from "./Withdraw.vue?vue&type=script&lang=js"
import style0 from "./Withdraw.vue?vue&type=style&index=0&id=4638c1ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4638c1ac",
  null
  
)

export default component.exports