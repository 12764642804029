<template>
  <div class="withdraw">
    <h3 class="title">{{ $t('wallet.withdraw') }}</h3>
    <p class="sub-title">
      {{ $t('wallet.sub_title_withdraw_main') }}
    </p>
    <hr />
    <b-row class="withdraw-content" v-if="netWorkChoice">
      <b-col xl="12">
        <p class="labelInput">
          {{ $t('wallet.withdrawAddress') }}
          <span>{{ $t('wallet.only_usd') }}</span>
        </p>
        <div class="form-group">
          <b-form-input type="text" class="form-control custom-input" v-model="formWithdraw.address"></b-form-input>
        </div>
        <p class="labelInput">
          {{ $t('wallet.amount') }} ({{ $t('wallet.min') }}: ${{
            netWorkChoice.MinWithdraw
          }})
        </p>
        <div class="form-group">
          <input type="number" v-model="formWithdraw.amount" class="form-control custom-input" />
          <div class="box-network" @click="showDropdownNetWork = !showDropdownNetWork">
            <img src="~@/assets/images/currency/usd.png" alt="" />
            <p>{{ netWorkChoice.id === 5 ? 'BEP20' : 'TRC20' }}</p>
            <img src="~@/assets/images/icons/gold-arrow-down.svg" alt="down" class="ml-1" />
            <div class="box-network-dropdown" @click.stop="onChoiceNetWork(netWorkChoice.id)" v-if="showDropdownNetWork">
              <img src="~@/assets/images/currency/usd.png" alt="" />
              <p>{{ netWorkChoice.id === 16 ? 'BEP20' : 'TRC20' }}</p>
            </div>
          </div>
        </div>
        <div class="predict-list" v-if="showPredict">
          <div class="predict-item" @click="formWithdraw.amount = amountPredict.amount1">
            <img src="~@/assets/images/currency/usd.png" alt="" class="mr-1" />
            {{ TruncateToDecimals2(amountPredict.amount1, '', 2) }}
          </div>
          <div class="predict-item" @click="formWithdraw.amount = amountPredict.amount2">
            <img src="~@/assets/images/currency/usd.png" alt="" class="mr-1" />
            {{ TruncateToDecimals2(amountPredict.amount2, '', 2) }}
          </div>
          <div class="predict-item" @click="formWithdraw.amount = amountPredict.amount3">
            <img src="~@/assets/images/currency/usd.png" alt="" class="mr-1" />
            {{ TruncateToDecimals2(amountPredict.amount3, '', 2) }}
          </div>
          <div class="predict-item" @click="formWithdraw.amount = amountPredict.amount4">
            <img src="~@/assets/images/currency/usd.png" alt="" class="mr-1" />
            {{ TruncateToDecimals2(amountPredict.amount4, '', 2) }}
          </div>
        </div>
        <p class="labelInput">{{ $t('wallet.auth') }}</p>
        <div class="form-group">
          <b-form-input type="number" class="form-control custom-input" v-model="formWithdraw.authCode"></b-form-input>
        </div>
        <div class="box-withdraw-info">
          <div class="withdraw-info-item">
            <div class="withdraw-info">
              <p class="labelInput text-white">{{ $t('wallet.fee') }} (1%):</p>
              <p class="labelInput text-white">{{ formWithdraw.amount * netWorkChoice.Fee / 100 }} USD</p>
            </div>
            <hr />
            <div class="withdraw-info">
              <p class="labelInput text-white">
                {{ $t('wallet.amount_expected_receive') }}:
              </p>
              <p class="labelInput text-white">
                {{ TruncateToDecimals2(amountExpected, '', 2) }} USD
              </p>
            </div>
          </div>
          <div class="withdraw-info-item">
            <b-button class="btnNoStyle btn-withdraw" @click="onWithdraw(netWorkChoice.id)" :disabled="!formWithdraw.amount ||
              !formWithdraw.authCode ||
              !formWithdraw.address ||
              +formWithdraw.amount < netWorkChoice.MinWithdraw
              ">{{ $t('wallet.withdraw') }}</b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
// eslint-disable-next-line no-undef
const WAValidator = require('multicoin-address-validator');

export default {
  name: 'WithdrawMainPageView',
  data() {
    return {
      showPredict: false,
      amountPredict: {
        amount1: 0,
        amount2: 0,
        amount3: 0,
        amount4: 0,
      },
      netWorkChoice: null,
      showDropdownNetWork: false,
      formWithdraw: {
        coin: '',
        amount: 1,
        address: '',
        authCode: '',
      },
      amountExpected: 0,
    };
  },
  computed: {
    ...mapGetters({
      Price: 'wallet/Price',
    }),
  },
  watch: {
    formWithdraw: {
      handler(newVal) {
        if (newVal && +newVal.amount !== 0 && +newVal.amount > 0) {
          this.showPredict = true;
          this.amountPredict = {
            amount1: this.formWithdraw.amount * 10,
            amount2: this.formWithdraw.amount * 100,
            amount3: this.formWithdraw.amount * 1000,
            amount4: this.formWithdraw.amount * 10000,
          };
          this.amountExpected =
            this.formWithdraw.amount - (this.formWithdraw.amount * +this.netWorkChoice.Fee / 100);
          // ------------------
          const amountSplit = this.formWithdraw.amount.toString().split('.');
          if (amountSplit[1] && amountSplit[1].length > 2) {
            this.formWithdraw.amount = this.enterTheNumberAfterTheComma2Numbers(amountSplit);
          }
        } else {
          this.showPredict = false;
        }
      },
      deep: true,
    },
    Price: {
      handler(newVal) {
        this.netWorkChoice = newVal[0];
      },
    },
  },
  methods: {
    ...mapMutations(['onload', 'outload']),
    onChoiceNetWork(idNetwork) {
      this.showDropdownNetWork = false;
      if (idNetwork === 5) {
        this.netWorkChoice = this.Price[1];
      } else {
        this.netWorkChoice = this.Price[0];
      }
    },
    resetForm() {
      this.formWithdraw = {
        coin: '',
        amount: 1,
        address: '',
        authCode: '',
      };
    },
    onWithdraw(coinId) {
      this.onload();
      setTimeout(async () => {
        let mainnet = '';
        switch (coinId) {
          case 16:
            mainnet = 'trx';
            break;
          case 5:
            mainnet = 'eth';
            break;
          default:
            break;
        }
        const valid = WAValidator.validate(this.formWithdraw.address, mainnet);
        if (valid) {
          this.formWithdraw.coin = coinId;
          // this.form.token = await this.genarateCaptChaV3('withdraw');
          this.$store
            .dispatch('wallet/req_postWithdraw', this.formWithdraw)
            .then(() => {
              this.resetForm();
            });
        } else {
          this.$toastr.e('Invalid address');
          this.outload();
        }
        this.outload();
      }, 2000);
    },
  },
  mounted() {
    this.netWorkChoice = this.Price[0];
  },
};
</script>

<style lang="scss" scoped>
.withdraw {
  .title {
    background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 23px;

    @media (max-width: 1400px) {
      font-size: 20px;
    }
  }

  .sub-title {
    color: #bebebe;
    font-size: 16px;

    @media (max-width: 1400px) {
      font-size: 14px;
    }
  }

  hr {
    border: 1px solid rgba(93, 184, 251, 0.42);
  }

  .withdraw-content {
    align-items: center;

    .labelInput {
      margin-bottom: 5px;
      color: #616161;
      font-weight: 600;

      @media (max-width: 1400px) {
        font-size: 14px;
      }

      span {
        background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }

    .form-group {
      display: flex;
      align-items: center;
      height: 50px;

      input {
        height: 100%;
      }

      .box-network {
        height: 100%;
        margin-left: 10px;
        border: 1px solid #797979;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        cursor: pointer;
        position: relative;

        p {
          margin-left: 5px;
          margin-bottom: 0px;
          line-height: 100%;

          @media (max-width: 1400px) {
            font-size: 14px;
          }
        }

        .box-network-dropdown {
          position: absolute;
          bottom: -60px;
          left: 0px;
          width: 100%;
          border: 1px solid #797979;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          height: 50px;
          background: #1b2c57;
        }
      }
    }

    .predict-list {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      overflow-x: auto;
      padding-bottom: 10px;

      .predict-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-basis: 150px;
        flex-shrink: 0;
        border: 1px solid rgba(93, 184, 251, 0.42);
        border-radius: 5px;
        text-align: center;
        height: 30px;
        cursor: pointer;
        padding: 0px 5px;
        margin: 0px 5px;

        &:hover {
          background: rgba(93, 184, 251, 0.42);
        }
      }
    }

    .box-withdraw-info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 576px) {
        flex-direction: column;
      }

      .withdraw-info-item {
        width: 48%;
        margin-top: 20px;

        @media (max-width: 576px) {
          width: 100%;
        }

        .withdraw-info {
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            margin-bottom: 0px;
          }
        }

        .btn-withdraw {
          width: 100%;
          height: 50px;
          background: linear-gradient(91.46deg,
              #0ec5ff 1.08%,
              #3964fb 83%) !important;
          border-radius: 3px;
          position: relative;
          z-index: 1;

          &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 50px;
            background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
            filter: blur(21px);
            z-index: -1;
          }
        }
      }
    }

    .note {
      margin-top: 30px;
      font-size: 14px;

      span {
        background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }

    .withdraw-qr {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .withdraw-qr-address {
        width: 100%;
        background: #1b2c57;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 7px;
        display: flex;
        margin-top: 15px;
        padding: 15px 0px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          color: #bebebe;
          margin-bottom: 0px;

          &.copy {
            color: #ffffff;
          }
        }

        .line {
          width: 1px;
          height: 20px;
          margin-left: 10px;
          background-color: #bebebe;
        }

        img {
          margin: 0px 10px;
        }
      }
    }
  }
}
</style>