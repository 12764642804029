<template>
  <div class="deposit">
    <h3 class="title">{{ $t('wallet.deposit') }}</h3>
    <p class="sub-title">{{ $t('wallet.sub_title_deposit_main') }}</p>
    <hr />
    <b-row class="deposit-content" v-if="netWorkChoice">
      <b-col sm="7" md="7" lg="7" xl="7">
        <p class="labelInput">
          {{ $t('wallet.amount_expected_deposit') }} ({{ $t('wallet.min') }}:
          ${{ netWorkChoice.Deposit }})
        </p>
        <div class="form-group">
          <b-form-input
            type="number"
            v-model="amountDeposit"
            class="form-control custom-input"
          ></b-form-input>
          <div
            class="box-network"
            @click="showDropdownNetWork = !showDropdownNetWork"
          >
            <img src="~@/assets/images/currency/usd.png" alt="" />
            <p>{{ netWorkChoice.id === 5 ? 'BEP20' : 'TRC20' }}</p>
            <img
              src="~@/assets/images/icons/gold-arrow-down.svg"
              alt="down"
              class="ml-1"
            />
            <div
              class="box-network-dropdown"
              @click.stop="onChoiceNetWork(netWorkChoice.id)"
              v-if="showDropdownNetWork"
            >
              <img src="~@/assets/images/currency/usd.png" alt="" />
              <p>{{ netWorkChoice.id === 16 ? 'BEP20' : 'TRC20' }}</p>
            </div>
          </div>
        </div>
        <div class="deposit-info">
          <p class="labelInput">{{ $t('wallet.fee') }}:</p>
          <p class="labelInput">0%</p>
        </div>
        <hr />
        <div class="deposit-info">
          <p class="labelInput">{{ $t('wallet.amount_expected_receive') }}:</p>
          <p class="labelInput">
            {{
              TruncateToDecimals2(
                +amountDepositExpectedReceive
                  ? +amountDepositExpectedReceive
                  : 0,
                '',
                2
              )
            }}
            USD
          </p>
        </div>
        <div class="note">
          <p>
            <span>{{ $t('note.important') }}: </span>
            {{ $t('note.send_only') }} <span>{{ netWorkChoice.Name }}</span>
            {{ $t('note.to_this_deposit') }}
          </p>
          <p>
            <span>{{ $t('note.notice') }} : </span>
            {{ $t('note.coin_will_be') }}
          </p>
          <p>
            <span>{{ $t('note.important') }}: </span>
            {{ $t('note.we_do_not_support') }}
          </p>
          <p class="text-effect">
            <span>{{ $t('note.important') }}: </span>
            {{ $t('note.warning_deposit') }}
          </p>
          <b-button
            class="btnNoStyle btn-refreshDeposit"
            @click="onRefreshDeposit"
            >Refresh</b-button
          >
        </div>
      </b-col>
      <b-col sm="5" md="5" lg="5" xl="5" class="mb-4">
        <div class="deposit-qr">
          <img :src="CoinAddress.Qr" alt="qr" class="qr-img" />
          <div class="deposit-qr-address">
            <p>{{ AddressDepositView }}</p>
            <div class="line"></div>
            <img src="~@/assets/images/icons/copy.png" alt="copy" />
            <b-button
              class="btnNoStyle"
              v-clipboard:copy="CoinAddress.address"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              >{{ $t('agency.copy') }}</b-button
            >
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DepositMainPageView',
  data() {
    return {
      amount: 0,
      showPredict: false,
      amountPredict: {
        amount1: 0,
        amount2: 0,
        amount3: 0,
        amount4: 0,
      },
      netWorkChoice: null,
      showDropdownNetWork: false,
      amountDeposit: 0,
      amountDepositExpectedReceive: 0,
    };
  },
  computed: {
    ...mapGetters({
      Price: 'wallet/Price',
      CoinAddress: 'wallet/CoinAddress',
    }),
    AddressDepositView() {
      const addressCurrent = this.CoinAddress.address;
      if (!addressCurrent) return null;
      const stringShort = `${addressCurrent.slice(
        0,
        5
      )}...${addressCurrent.slice(
        addressCurrent.length - 5,
        addressCurrent.length
      )}`;
      return stringShort;
    },
  },
  watch: {
    amount: {
      handler(newVal) {
        if (newVal && +newVal !== 0) {
          this.showPredict = true;
          this.amountPredict = {
            amount1: this.amount * 10,
            amount2: this.amount * 100,
            amount3: this.amount * 1000,
            amount4: this.amount * 10000,
          };
        } else {
          this.showPredict = false;
        }
      },
      deep: true,
    },
    Price: {
      handler(newVal) {
        this.netWorkChoice = newVal[0];
      },
    },
    netWorkChoice: {
      handler(newVal) {
        if (newVal) {
          this.onGetAddressDeposit(newVal.id);
        }
      },
    },
    amountDeposit: {
      handler(newVal) {
        if (newVal) {
          this.amountDepositExpectedReceive = newVal;
        } else {
          this.amountDepositExpectedReceive = 0;
        }
      },
    },
  },
  methods: {
    onChoiceNetWork(idNetwork) {
      this.showDropdownNetWork = false;
      if (idNetwork === 5) {
        this.netWorkChoice = this.Price[1];
      } else {
        this.netWorkChoice = this.Price[0];
      }
    },
    onGetAddressDeposit(idNetwork) {
      this.$store.dispatch('wallet/req_getAddress', idNetwork);
    },
    onCopy: function (e) {
      this.$toastr.s(e.text, this.$t('agency.copy'));
    },
    onError: function (e) {
      this.$toastr.e(e.text, this.$t('agency.failed_please_try_again'));
    },
    onRefreshDeposit() {
      this.$store.dispatch('wallet/req_getRefreshBalanceDeposit');
    },
  },
  mounted() {
    this.netWorkChoice = this.Price[0];
  },
};
</script>

<style lang="scss" scoped>
.deposit {
  .title {
    background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 23px;

    @media (max-width: 1400px) {
      font-size: 20px;
    }
  }

  .sub-title {
    color: #bebebe;
    font-size: 16px;

    @media (max-width: 1400px) {
      font-size: 14px;
    }
  }

  hr {
    border: 1px solid rgba(93, 184, 251, 0.42);
  }

  .deposit-content {
    align-items: center;

    @media (max-width: 992px) {
      flex-direction: column-reverse;
    }

    .labelInput {
      margin-bottom: 5px;

      @media (max-width: 1400px) {
        font-size: 14px;
      }
    }

    .form-group {
      display: flex;
      align-items: center;
      height: 50px;

      input {
        height: 100%;
      }

      .box-network {
        height: 100%;
        margin-left: 10px;
        border: 1px solid #797979;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        cursor: pointer;
        position: relative;

        p {
          margin-left: 5px;
          margin-bottom: 0px;
          line-height: 100%;

          @media (max-width: 1400px) {
            font-size: 14px;
          }
        }

        .box-network-dropdown {
          position: absolute;
          bottom: -60px;
          left: 0px;
          width: 100%;
          border: 1px solid #797979;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          height: 50px;
          background: #1b2c57;
        }
      }
    }

    .deposit-info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin-bottom: 0px;
      }
    }

    .note {
      margin-top: 30px;
      font-size: 14px;

      @media (max-width: 1400px) {
        font-size: 12px;
      }

      span {
        background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
      .btn-refreshDeposit {
        width: 100%;
        height: 50px;
        background: linear-gradient(
          91.46deg,
          #0ec5ff 1.08%,
          #3964fb 83%
        ) !important;
        border-radius: 3px;
        position: relative;
        z-index: 1;

        &::before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          height: 50px;
          background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
          filter: blur(21px);
          z-index: -1;
        }
      }
    }

    .deposit-qr {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .qr-img {
        width: 200px;

        @media (max-width: 1400px) {
          width: 120px;
        }
      }

      .deposit-qr-address {
        width: 100%;
        background: #1b2c57;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 7px;
        display: flex;
        margin-top: 15px;
        padding: 15px 0px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          color: #bebebe;
          margin-bottom: 0px;
          font-size: 14px;

          @media (max-width: 1400px) {
            font-size: 12px;
          }

          &.copy {
            color: #ffffff;
          }
        }

        .line {
          width: 1px;
          height: 20px;
          margin-left: 10px;
          background-color: #bebebe;

          @media (max-width: 1400px) {
            margin-left: 5px;
          }
        }

        img {
          margin: 0px 10px;

          @media (max-width: 1400px) {
            width: 10px;
            margin: 0px 5px;
          }
        }

        button {
          font-size: 14px;

          @media (max-width: 1400px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}
.text-effect {
  animation: pulsate 3s infinite alternate;
}
@keyframes pulsate {
  // 100% {
  //   text-shadow: 0 0 2px #fff, 0 0 9px #fff, 0 0 17px #fff, 0 0 20px #ffe500 ,
  //     0 0 40px #ffe500 , 0 0 50px #ffe500 , 0 0 60px #ffe500 , 0 0 110px #ffe500 ;
  // }

  0% {
    text-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 3px #fff, 0 0 5px #ffe500 ,
      0 0 20px #ffe500 , 0 0 30px #ffe500 , 0 0 45px #ffe500 , 0 0 55px #ffe500 ;
  }
}
</style>