<template>
  <div class="transaction">
    <h3>{{ $t('exchange.history') }}</h3>
    <div class="transaction-history">
      <div class="history-tab">
        <div class="listTab">
          <b-button
            class="btnNoStyle tab-item"
            :class="activeTab === 'main' ? 'activeTab' : ''"
            @click="onGetActiveTab('main')"
            >{{ $t('wallet.main') }}</b-button
          >
          <b-button
            class="btnNoStyle tab-item"
            :class="activeTab === 'game' ? 'activeTab' : ''"
            @click="onGetActiveTab('game')"
            >{{ $t('wallet.game') }}</b-button
          >
        </div>
      </div>
      <div class="historyTable">
        <!-- Game -->
        <template v-if="activeTab === 'game'">
          <div class="table-responsive" v-if="!showTableMobile">
            <b-table
              :items="History"
              :fields="fields"
              show-empty
              class="myTable"
            >
              <template #empty="">
                <h5 class="text-center p-2">
                  {{ $t('support.no_data_available_in_table') }}
                </h5>
              </template>
              <template #cell(Money_MoneyAction)="data">
                {{ Filter(data.value) }}
              </template>
              <template #cell(Money_Time)="data">
                {{ getDateTime(data.value * 1000, 'DD/MM/YYYY') }}
              </template>
              <template #cell(Money_MoneyStatus)="data">
                <div
                  :class="{
                    'text-win':
                      data.item.Money_MoneyAction === 2
                        ? data.item.Money_Confirm === 1
                        : data.item.Money_MoneyStatus === 1,
                    'text-pending':
                      data.item.Money_MoneyAction === 2
                        ? data.item.Money_Confirm === 2
                        : data.item.Money_MoneyStatus === 2,
                    'text-lose':
                      (data.value === data.item.Money_MoneyAction) === 2
                        ? data.item.Money_Confirm === 3
                        : data.item.Money_MoneyStatus === 3,
                  }"
                >
                  {{
                    data.item.Money_MoneyAction === 2
                      ? StatusFilter(data.item.Money_Confirm)
                      : StatusFilter(data.item.Money_MoneyStatus)
                  }}
                </div>
              </template>
            </b-table>
          </div>
          <div class="table-mobile" v-else>
            <template v-if="History && History.length > 0">
              <div
                v-for="(history, idx) in History"
                :key="'profitHistory: ' + idx"
                class="w-100"
                role="tablist"
              >
                <b-button
                  v-b-toggle="`profitHistory-${idx}`"
                  class="btnNoStyle header"
                  role="tab"
                  >{{ $t('wallet.amount') }}:
                  {{ TruncateToDecimals2(Number(history.Money_USDT), '', 2) }}$
                  - {{ Filter(history.Money_MoneyAction) }} -
                  {{ getDateTime(history.Money_Time * 1000) }}</b-button
                >
                <b-collapse
                  :id="`profitHistory-${idx}`"
                  class="mt-2"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <ul>
                    <li>
                      Id: <span>{{ history.Money_ID }}</span>
                    </li>
                    <li>
                      {{ $t('wallet.address') }}: <span>{{ history.Money_Address }}</span>
                    </li>
                    <li>
                      {{ $t('support.content') }}: <span>{{ history.Money_Comment }}</span>
                    </li>
                    <li>
                      {{ $t('support.action') }}:
                      <span>{{ Filter(history.Money_MoneyAction) }}</span>
                    </li>
                    <li>
                      {{ $t('wallet.amount') }}:
                      <span>{{
                        TruncateToDecimals2(Number(history.Money_USDT), '', 2)
                      }}</span>
                    </li>
                    <li>
                      {{ $t('wallet.rate') }}: <span>{{ history.Money_Rate }}</span>
                    </li>
                    <li>
                      {{ $t('wallet.fee') }}: <span>{{ history.Money_USDTFee }}</span>
                    </li>
                    <li>
                      {{ $t('wallet.time') }}:
                      <span>{{ getDateTime(history.Money_Time * 1000) }}</span>
                    </li>
                    <li>
                      {{ $t('wallet.status') }}:
                      <span
                        :class="{
                          'text-win':
                            history.Money_MoneyAction === 2
                              ? history.Money_Confirm === 1
                              : history.Money_MoneyStatus === 1,
                          'text-pending':
                            history.Money_MoneyAction === 2
                              ? history.Money_Confirm === 2
                              : history.Money_MoneyStatus === 2,
                          'text-lose':
                            history.Money_MoneyAction === 2
                              ? history.Money_Confirm === 3
                              : history.Money_MoneyStatus === 3,
                        }"
                      >
                        {{
                          history.Money_MoneyAction === 2
                            ? StatusFilter(history.Money_Confirm)
                            : StatusFilter(history.Money_MoneyStatus)
                        }}
                      </span>
                    </li>
                  </ul>
                </b-collapse>
              </div>
            </template>
            <p v-else class="text-center">
              {{ $t('support.no_data_available_in_table') }}
            </p>
          </div>
          <div class="d-flex w-100 justify-content-end align-items-center">
            <paginate
              :totalPage="HistoryTotalPage"
              @current="onChangePageHistoryGame"
            />
          </div>
        </template>
        <!-- Main -->
        <template v-if="activeTab === 'main'">
          <div class="table-responsive" v-if="!showTableMobile">
            <b-table
              :items="History"
              :fields="fields"
              show-empty
              class="myTable"
            >
              <template #empty="">
                <h5 class="text-center p-2">
                  {{ $t('support.no_data_available_in_table') }}
                </h5>
              </template>
              <template #cell(Money_MoneyAction)="data">
                {{ Filter(data.value) }}
              </template>
              <template #cell(Money_Time)="data">
                {{ getDateTime(data.value * 1000, 'DD/MM/YYYY') }}
              </template>
              <template #cell(Money_MoneyStatus)="data">
                <div
                  :class="{
                    'text-win':
                      data.item.Money_MoneyAction === 2
                        ? data.item.Money_Confirm === 1
                        : data.item.Money_MoneyStatus === 1,
                    'text-pending':
                      data.item.Money_MoneyAction === 2
                        ? data.item.Money_Confirm === 2
                        : data.item.Money_MoneyStatus === 2,
                    'text-lose':
                      (data.value === data.item.Money_MoneyAction) === 2
                        ? data.item.Money_Confirm === 3
                        : data.item.Money_MoneyStatus === 3,
                  }"
                >
                  {{
                    data.item.Money_MoneyAction === 2
                      ? StatusFilter(data.item.Money_Confirm)
                      : StatusFilter(data.item.Money_MoneyStatus)
                  }}
                </div>
              </template>
            </b-table>
          </div>
          <div class="table-mobile" v-else>
            <template v-if="History && History.length > 0">
              <div
                v-for="(history, idx) in History"
                :key="'profitHistory: ' + idx"
                class="w-100"
                role="tablist"
              >
                <b-button
                  v-b-toggle="`profitHistory-${idx}`"
                  class="btnNoStyle header"
                  role="tab"
                  >{{ $t('wallet.amount') }}:
                  {{ TruncateToDecimals2(Number(history.Money_USDT), '', 2) }}$
                  - {{ Filter(history.Money_MoneyAction) }} -
                  {{ getDateTime(history.Money_Time * 1000) }}</b-button
                >
                <b-collapse
                  :id="`profitHistory-${idx}`"
                  class="mt-2"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <ul>
                    <li>
                      Id: <span>{{ history.Money_ID }}</span>
                    </li>
                    <li>
                      {{ $t('wallet.address') }}: <span>{{ history.Money_Address }}</span>
                    </li>
                    <li>
                      {{ $t('support.content') }}: <span>{{ history.Money_Comment }}</span>
                    </li>
                    <li>
                      {{ $t('support.action') }}:
                      <span>{{ Filter(history.Money_MoneyAction) }}</span>
                    </li>
                    <li>
                      {{ $t('wallet.amount') }}:
                      <span>{{
                        TruncateToDecimals2(Number(history.Money_USDT), '', 2)
                      }}</span>
                    </li>
                    <li>
                      {{ $t('wallet.rate') }}: <span>{{ history.Money_Rate }}</span>
                    </li>
                    <li>
                      {{ $t('wallet.fee') }}: <span>{{ history.Money_USDTFee }}</span>
                    </li>
                    <li>
                      {{ $t('wallet.time') }}:
                      <span>{{ getDateTime(history.Money_Time * 1000) }}</span>
                    </li>
                    <li>
                      {{ $t('wallet.status') }}:<span
                        :class="{
                          'text-win':
                            history.Money_MoneyAction === 2
                              ? history.Money_Confirm === 1
                              : history.Money_MoneyStatus === 1,
                          'text-pending':
                            history.Money_MoneyAction === 2
                              ? history.Money_Confirm === 2
                              : history.Money_MoneyStatus === 2,
                          'text-lose':
                            history.Money_MoneyAction === 2
                              ? history.Money_Confirm === 3
                              : history.Money_MoneyStatus === 3,
                        }"
                      >
                        {{
                          history.Money_MoneyAction === 2
                            ? StatusFilter(history.Money_Confirm)
                            : StatusFilter(history.Money_MoneyStatus)
                        }}
                      </span>
                    </li>
                  </ul>
                </b-collapse>
              </div>
            </template>
            <p v-else class="text-center">
              {{ $t('support.no_data_available_in_table') }}
            </p>
          </div>
          <div class="d-flex w-100 justify-content-end align-items-center">
            <paginate
              :totalPage="HistoryTotalPage"
              @current="onChangePageHistoryMain"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from '@/components/Shared/Pagination.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'HistoryWalletViewPage',
  components: {
    Paginate,
  },
  data() {
    return {
      activeTab: 'main',

      widthL: 0,
      showTableMobile: false,
    };
  },
  computed: {
    ...mapGetters({
      History: 'history/History',
      Filter: 'history/Filter',
      HistoryTotalPage: 'history/HistoryTotalPage',
    }),
    fields() {
      const fieldMain = [
        {
          key: 'Money_ID',
          label: 'ID',
          class: '',
        },
        {
          key: 'Money_USDT',
          label: this.$t('wallet.amount'),
          class: 'text-center',
        },
        {
          key: 'Money_Rate',
          label: this.$t('wallet.rate'),
          class: 'text-center',
        },
        {
          key: 'Money_USDTFee',
          label: this.$t('wallet.fee'),
          class: 'text-center',
        },
        {
          key: 'Money_Address',
          label: 'Hash',
          class: 'canWrap',
        },
        {
          key: 'Money_Comment',
          label: this.$t('support.content'),
          class: 'text-center',
        },
        {
          key: 'Money_MoneyAction',
          label: this.$t('support.action'),
          class: 'text-center',
        },
        {
          key: 'Money_Time',
          label: this.$t('wallet.time'),
          class: 'text-center',
        },
        {
          key: 'Money_MoneyStatus',
          label: this.$t('wallet.status'),
          class: 'text-center',
        },
      ];
      const fieldGame = [
        {
          key: 'Money_ID',
          label: 'ID',
          class: '',
        },
        {
          key: 'Money_USDT',
          label: this.$t('wallet.amount'),
          class: 'text-center',
        },
        {
          key: 'Money_Rate',
          label: this.$t('wallet.rate'),
          class: 'text-center',
        },
        {
          key: 'Money_USDTFee',
          label: this.$t('wallet.fee'),
          class: 'text-center',
        },
        {
          key: 'Money_Comment',
          label: this.$t('support.content'),
          class: 'text-center',
        },
        {
          key: 'Money_MoneyAction',
          label: this.$t('support.action'),
          class: 'text-center',
        },
        {
          key: 'Money_Time',
          label: this.$t('wallet.time'),
          class: 'text-center',
        },
        {
          key: 'Money_MoneyStatus',
          label: this.$t('wallet.status'),
          class: 'text-center',
        },
      ];
      if (this.activeTab != 'main') {
        return fieldGame;
      }
      return fieldMain;
    },
  },
  methods: {
    StatusFilter(id) {
      switch (id) {
        case 1:
          return 'Confirm';
        case 2:
          return 'Pending';
        case 3:
          return 'Canceled';
        default:
          return '';
      }
    },
    onChangePageHistoryGame(payload) {
      this.$store.dispatch('history/req_getHistory', {
        type: 'exchange',
        page: payload,
      });
    },
    onChangePageHistoryMain(payload) {
      this.$store.dispatch('history/req_getHistory', {
        type: 'wallet',
        page: payload,
      });
    },
    checkResizeWindow() {
      this.widthL = window.innerWidth;
    },
    onGetActiveTab(tab) {
      this.activeTab = tab;
      if (tab === 'main') {
        this.$store.dispatch('history/req_getHistory', {
          type: 'wallet',
          page: 1,
        });
      }
      if (tab === 'game') {
        this.$store.dispatch('history/req_getHistory', {
          type: 'exchange',
          page: 1,
        });
      }
    },
  },
  created() {
    this.$store.dispatch('history/req_getHistory', { type: 'wallet', page: 1 });
    // this.$store.dispatch('history/req_getMoneyActionsFilter');
  },
  async mounted() {
    this.checkResizeWindow();
    this.windowEvent = () => {
      this.checkResizeWindow();
    };
    if (this.widthL <= 576) {
      this.showTableMobile = true;
    } else {
      this.showTableMobile = false;
    }
  },
};
</script>

<!-- LOCAL STYLE -->
<style lang="scss" scoped>
.transaction {
  h3 {
    background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 23px;
  }

  .history-tab {
    padding: 5px;
    background: rgba(27, 72, 187, 0.2);
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(34px);
    border-radius: 7px;
    margin-bottom: 40px;
    max-width: 100%;
    width: fit-content;
    overflow-x: auto;
    overflow-y: hidden;

    .listTab {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .tab-item {
        padding: 0px 25px;
        height: 55px;

        @media (max-width: 576px) {
          min-width: 130px;
          font-size: 14px;
          padding: 0px 10px;
          height: 35px;
        }

        &.activeTab {
          background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
          border-radius: 6px;
        }
      }
    }
  }

  .botan {
    &.asTitle {
      padding: 10px 30px;
      font-size: 18px;
      font-weight: 700;
    }
  }

  .transaction-history {
    margin-top: 20px;
    background: linear-gradient(180deg, #081e43 0%, rgba(1, 6, 15, 0.63) 100%);
    border-radius: 4px;
    padding: 20px;
    @media (max-width: 376px) {
      padding: 10px;
    }

    .title {
      font-size: 30px;
      background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin-bottom: 20px;

      @media (max-width: 576px) {
        font-size: 22px;
      }
    }

    .historyTable {
    }

    .table-mobile {
      width: 100%;
      background: linear-gradient(
        180deg,
        #081e43 0%,
        rgba(1, 6, 15, 0.63) 100%
      );
      border-radius: 4px;
      padding: 20px;
      @media (max-width: 376px) {
        padding: 0px;
      }

      .header {
        width: 100%;
        background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
        border-radius: 6px;
        padding: 0px 25px;
        min-height: 55px;
        margin-bottom: 20px;
      }

      .collapse {
        margin-top: -20px !important;
        padding: 20px 10px;
        box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
        -webkit-backdrop-filter: blur(34px);
        backdrop-filter: blur(34px);
        border-radius: 7px;
        margin-bottom: 20px;

        P {
          margin-bottom: 0px;
        }

        ul {
          li {
            font-weight: 700;

            span {
              word-wrap: break-word;
              font-weight: 400;
              color: rgb(219, 224, 235);
            }
          }
        }
      }
    }
  }
}
</style>
