<template>
  <b-row class="stake-statistic" v-if="StatisticStake">
    <b-col cols="12" sm="12" md="6" lg="6" xl="3" class="mb-3">
      <div class="stake-statistic-item">
        <p>{{ $t('wallet.balanceMain') }}</p>
        <hr />
        <div class="amount">
          <p>{{ TruncateToDecimals2(+UserBalance.MainBalance, '', 2) }}</p>
          <img src="~@/assets/images/currency/usd.png" alt="" />
        </div>
      </div>
    </b-col>
    <b-col cols="12" sm="12" md="6" lg="6" xl="3" class="mb-3">
      <div class="stake-statistic-item">
        <p>{{ $t('wallet.balance') }} staking</p>
        <hr />
        <div class="amount">
          <p>{{ TruncateToDecimals2(+UserBalance.StakingBalance, '', 2) }}</p>
          <img src="~@/assets/images/currency/usd.png" alt="" />
        </div>
      </div>
    </b-col>
    <b-col cols="12" sm="12" md="6" lg="6" xl="3" class="mb-3">
      <div class="stake-statistic-item">
        <p>{{ $t('wallet.balanceGame') }} (BS)</p>
        <hr />
        <div class="amount">
          <p>
            {{ TruncateToDecimals2(+UserBalance.BSBalance, '', 2) }}
          </p>
          <img src="~@/assets/images/currency/bs.png" alt="" />
        </div>
      </div>
    </b-col>
    <b-col cols="12" sm="12" md="6" lg="6" xl="3" class="mb-3">
      <div class="stake-statistic-item">
        <p>{{ $t('wallet.balanceGame') }} (SBS)</p>
        <hr />
        <div class="amount">
          <p>
            {{ TruncateToDecimals2(+UserBalance.SBSBalance, '', 2) }}
          </p>
          <img src="~@/assets/images/currency/sbs.png" alt="" />
        </div>
      </div>
    </b-col>
    <b-col cols="12" sm="12" md="6" lg="6" xl="3" class="mb-3">
      <div class="stake-statistic-item">
        <p class="d-flex justify-content-between align-items-center">
          {{ $t('wallet.balance') }} demo
          <b-button
            class="btn-refresh"
            @click="onRefreshBalanceDemo"
            :disabled="
              TruncateToDecimals2(
                +UserBalance.DBSBalance ? +UserBalance.DBSBalance : 0,
                '',
                2
              ) > 1000
            "
            >{{ $t('wallet.refresh') }}</b-button
          >
        </p>
        <hr />
        <div class="amount">
          <p>
            {{
              TruncateToDecimals2(
                +UserBalance.DBSBalance ? +UserBalance.DBSBalance : 0,
                '',
                2
              )
            }}
          </p>
          <img src="~@/assets/images/currency/dbs.png" alt="" />
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'StatisticWalletViewPage',
  computed: {
    ...mapGetters({
      StatisticStake: 'stake/StatisticStake',
      UserBalance: 'userinfo/UserBalance',
    }),
  },
  methods: {
    onRefreshBalanceDemo() {
      this.$store.dispatch('wallet/req_postRefreshBalanceDemo')
    },
  },
  created() {
    // this.$store.dispatch('stake/req_getStatisticStake');
  },
};
</script>
<style lang="scss" scoped>
.stake-statistic {
  align-items: center;
  width: 100%;
  @media (max-width: 576px) {
    width: inherit;
    padding: 0px 10px;
    margin: 0px 0px 40px;
  }

  .stake-statistic-item {
    padding: 20px 20px 40px;
    background: #081b3c;
    border-radius: 8px;

    @media (max-width: 376px) {
      padding: 20px 10px;
    }

    p {
      color: #bebebe;
      font-size: 18px;
      font-weight: 400;

      @media (max-width: 1400px) {
        font-size: 16px;
      }

      @media (max-width: 576px) {
        font-size: 14px;
      }
      .btn-refresh {
        height: 40px;
        background: linear-gradient(
          91.46deg,
          #0ec5ff 1.08%,
          #3964fb 83%
        ) !important;
        border-radius: 3px;
        position: relative;
        z-index: 1;

        &::before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          height: 40px;
          background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
          filter: blur(21px);
          z-index: -1;
        }
      }
    }

    hr {
      border: 1px solid #404040;
      margin-bottom: 30px;
    }

    .amount {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: 30px;
        margin-bottom: 0px;
        background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-weight: bold;

        @media (max-width: 1400px) {
          font-size: 22px;
        }

        @media (max-width: 576px) {
          font-size: 18px;
        }
      }

      img {
        width: 30px;

        @media (max-width: 1400px) {
          width: 25px;
        }
      }
    }
  }
}
</style>