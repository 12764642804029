<template>
  <div class="transfer">
    <h3 class="title">{{ $t('wallet.transfer') }}</h3>
    <p class="sub-title">
      {{ $t('wallet.sub_title_transfer_main') }}
    </p>
    <hr />
    <b-row class="transfer-content" v-if="netWorkChoice">
      <b-col xl="12">
        <p class="labelInput">{{ $t('promotion_insurance.user_id') }}</p>
        <div class="form-group">
          <b-form-input
            type="text"
            class="form-control custom-input"
            v-model="formTransfer.user_id"
          ></b-form-input>
        </div>
        <p class="labelInput">
          {{ $t('promotion_insurance.amount') }} ({{ $t('wallet.min') }}: ${{
            netWorkChoice.MinTransfer
          }})
        </p>
        <div class="form-group">
          <input
            type="number"
            v-model="formTransfer.amount"
            class="form-control custom-input"
          />
        </div>
        <div class="predict-list" v-if="showPredict">
          <div
            class="predict-item"
            @click="formTransfer.amount = amountPredict.amount1"
          >
            <img src="~@/assets/images/currency/usd.png" alt="" class="mr-1" />
            {{ TruncateToDecimals2(amountPredict.amount1, '', 2) }}
          </div>
          <div
            class="predict-item"
            @click="formTransfer.amount = amountPredict.amount2"
          >
            <img src="~@/assets/images/currency/usd.png" alt="" class="mr-1" />
            {{ TruncateToDecimals2(amountPredict.amount2, '', 2) }}
          </div>
          <div
            class="predict-item"
            @click="formTransfer.amount = amountPredict.amount3"
          >
            <img src="~@/assets/images/currency/usd.png" alt="" class="mr-1" />
            {{ TruncateToDecimals2(amountPredict.amount3, '', 2) }}
          </div>
          <div
            class="predict-item"
            @click="formTransfer.amount = amountPredict.amount4"
          >
            <img src="~@/assets/images/currency/usd.png" alt="" class="mr-1" />
            {{ TruncateToDecimals2(amountPredict.amount4, '', 2) }}
          </div>
        </div>
        <p class="labelInput">{{ $t('wallet.auth') }}</p>
        <div class="form-group">
          <b-form-input
            type="number"
            class="form-control custom-input"
            v-model="formTransfer.authCode"
          ></b-form-input>
        </div>
        <div class="box-transfer-info">
          <div class="transfer-info-item">
            <div class="transfer-info">
              <p class="labelInput text-white">{{ $t('wallet.fee') }}:</p>
              <p class="labelInput text-white">
                {{ netWorkChoice.FeeTransfer }}%
              </p>
            </div>
            <hr />
            <div class="transfer-info">
              <p class="labelInput text-white">
                {{ $t('wallet.amount_expected_receive') }}:
              </p>
              <p class="labelInput text-white">
                {{ TruncateToDecimals2(amountExpected, '', 2) }} USD
              </p>
            </div>
          </div>
          <div class="transfer-info-item">
            <b-button
              class="btnNoStyle btn-transfer"
              @click="onTransfer"
              :disabled="
                !formTransfer.amount ||
                !formTransfer.authCode ||
                !formTransfer.user_id ||
                +formTransfer.amount < 0 ||
                +formTransfer.amount > netWorkChoice.Balance ||
                +formTransfer.amount < +netWorkChoice.MinTransfer
              "
              >{{ $t('wallet.transfer') }}</b-button
            >
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TransferMainPageView',
  data() {
    return {
      showPredict: false,
      amountPredict: {
        amount1: 0,
        amount2: 0,
        amount3: 0,
        amount4: 0,
      },
      formTransfer: {
        amount: 1,
        user_id: '',
        authCode: '',
      },
      netWorkChoice: null,
      showDropdownNetWork: false,
      amountExpected: 0,
    };
  },
  computed: {
    ...mapGetters({
      Price: 'wallet/Price',
    }),
  },
  watch: {
    formTransfer: {
      handler(newVal) {
        if (newVal && +newVal.amount !== 0 && +newVal.amount > 0) {
          this.showPredict = true;
          this.amountPredict = {
            amount1: this.formTransfer.amount * 10,
            amount2: this.formTransfer.amount * 100,
            amount3: this.formTransfer.amount * 1000,
            amount4: this.formTransfer.amount * 10000,
          };
          this.amountExpected =
            this.formTransfer.amount *
            (1 - this.netWorkChoice.FeeTransfer / 100);
          // ------------------
          const amountSplit = this.formTransfer.amount.toString().split('.');
          if (amountSplit[1] && amountSplit[1].length > 2) {
            this.formTransfer.amount =
              this.enterTheNumberAfterTheComma2Numbers(amountSplit);
          }
        } else {
          this.showPredict = false;
        }
      },
      deep: true,
    },
    Price: {
      handler(newVal) {
        this.netWorkChoice = newVal[0];
      },
    },
  },
  methods: {
    resetForm() {
      this.formWithdraw = {
        amount: this.netWorkChoice.MinTransfer,
        user_id: '',
        authCode: '',
      };
    },
    onTransfer() {
      this.$store
        .dispatch('wallet/req_postTransfer', this.formTransfer)
        .then(() => {
          this.resetForm();
        });
    },
  },
  mounted() {
    this.netWorkChoice = this.Price[0];
  },
};
</script>

<style lang="scss" scoped>
.transfer {
  .title {
    background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 23px;

    @media (max-width: 1400px) {
      font-size: 20px;
    }
  }

  .sub-title {
    color: #bebebe;
    font-size: 16px;

    @media (max-width: 1400px) {
      font-size: 14px;
    }
  }

  hr {
    border: 1px solid rgba(93, 184, 251, 0.42);
  }

  .transfer-content {
    align-items: center;

    .labelInput {
      margin-bottom: 5px;
      color: #616161;
      font-weight: 600;

      @media (max-width: 1400px) {
        font-size: 14px;
      }

      span {
        background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }

    .form-group {
      display: flex;
      align-items: center;
      height: 50px;

      input {
        height: 100%;
      }

      .box-network {
        height: 100%;
        margin-left: 10px;
        border: 1px solid #797979;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        cursor: pointer;
        position: relative;

        p {
          margin-left: 5px;
          margin-bottom: 0px;
          line-height: 100%;

          @media (max-width: 1400px) {
            font-size: 14px;
          }
        }

        .box-network-dropdown {
          position: absolute;
          bottom: -60px;
          left: 0px;
          width: 100%;
          border: 1px solid #797979;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          height: 50px;
          background: #1b2c57;
        }
      }
    }

    .predict-list {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      overflow-x: auto;
      padding-bottom: 10px;

      .predict-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-basis: 150px;
        flex-shrink: 0;
        border: 1px solid rgba(93, 184, 251, 0.42);
        border-radius: 5px;
        text-align: center;
        height: 30px;
        cursor: pointer;
        padding: 0px 5px;
        margin: 0px 5px;

        &:hover {
          background: rgba(93, 184, 251, 0.42);
        }
      }
    }

    .box-transfer-info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 576px) {
        flex-direction: column;
      }

      .transfer-info-item {
        width: 48%;
        margin-top: 20px;

        @media (max-width: 576px) {
          width: 100%;
        }

        .transfer-info {
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            margin-bottom: 0px;
          }
        }

        .btn-transfer {
          width: 100%;
          height: 50px;
          background: linear-gradient(
            91.46deg,
            #0ec5ff 1.08%,
            #3964fb 83%
          ) !important;
          border-radius: 3px;
          position: relative;
          z-index: 1;

          &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 50px;
            background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
            filter: blur(21px);
            z-index: -1;
          }
        }
      }
    }

    .note {
      margin-top: 30px;
      font-size: 14px;

      span {
        background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }

    .transfer-qr {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .transfer-qr-address {
        width: 100%;
        background: #1b2c57;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 7px;
        display: flex;
        margin-top: 15px;
        padding: 15px 0px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          color: #bebebe;
          margin-bottom: 0px;

          &.copy {
            color: #ffffff;
          }
        }

        .line {
          width: 1px;
          height: 20px;
          margin-left: 10px;
          background-color: #bebebe;
        }

        img {
          margin: 0px 10px;
        }
      }
    }
  }
}
</style>