<template>
  <div class="wallet">
    <b-row class="mb-3 statistic-content">
      <StatisticalWallet />
    </b-row>
    <b-row>
      <b-col xl="6" class="mb-5">
        <div class="box-wallet">
          <h3>{{ $t('wallet.main') }}</h3>
          <div class="wallet-tab">
            <div class="listTab">
              <b-button
                class="btnNoStyle tab-item"
                :class="activeTabMain === 'Deposit' ? 'activeTab' : ''"
                @click="activeTabMain = 'Deposit'"
                >{{ $t('wallet.deposit') }}</b-button
              >
              <b-button
                class="btnNoStyle tab-item"
                :class="activeTabMain === 'Withdraw' ? 'activeTab' : ''"
                @click="activeTabMain = 'Withdraw'"
                >{{ $t('wallet.withdraw') }}</b-button
              >
              <b-button
                class="btnNoStyle tab-item"
                :class="activeTabMain === 'Transfer' ? 'activeTab' : ''"
                @click="activeTabMain = 'Transfer'"
                >{{ $t('wallet.transfer') }}</b-button
              >
            </div>
          </div>
          <div class="wallet-content">
            <DepositMain v-if="activeTabMain === 'Deposit'" />
            <WithdrawMain v-if="activeTabMain === 'Withdraw'" />
            <TransferMain v-if="activeTabMain === 'Transfer'" />
          </div>
        </div>
      </b-col>
      <b-col xl="6" class="mb-5">
        <div class="box-wallet">
          <h3>{{ $t('wallet.game') }}</h3>
          <div class="wallet-tab">
            <div class="listTab">
              <b-button
                class="btnNoStyle tab-item"
                :class="activeTabGame === 'Deposit' ? 'activeTab' : ''"
                @click="activeTabGame = 'Deposit'"
                >{{ $t('wallet.deposit') }}</b-button
              >
              <b-button
                class="btnNoStyle tab-item"
                :class="activeTabGame === 'Withdraw' ? 'activeTab' : ''"
                @click="activeTabGame = 'Withdraw'"
                >{{ $t('wallet.withdraw') }}</b-button
              >
            </div>
          </div>
          <div class="wallet-content">
            <DepositGame v-if="activeTabGame === 'Deposit'" />
            <WithdrawGame v-if="activeTabGame === 'Withdraw'" />
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col lg="12">
        <HistoryWallet />
      </b-col>
    </b-row>
  </div>
</template>

<script>
// Main
import DepositMain from '@/components/Wallet/Main/Deposit.vue';
import WithdrawMain from '@/components/Wallet/Main/Withdraw.vue';
import TransferMain from '@/components/Wallet/Main/Transfer.vue';

// Game
import DepositGame from '@/components/Wallet/Game/Deposit.vue';
import WithdrawGame from '@/components/Wallet/Game/Withdraw.vue';

// History
import HistoryWallet from '@/components/Wallet/History.vue';

// Statistical
import StatisticalWallet from '@/components/Wallet/Statistics.vue';

export default {
  name: 'WalletViewPage',
  components: {
    DepositMain,
    WithdrawMain,
    TransferMain,
    DepositGame,
    WithdrawGame,
    HistoryWallet,
    StatisticalWallet,
  },
  data() {
    return {
      activeTabMain: 'Deposit',
      activeTabGame: 'Deposit',
    };
  },
  created() {
    // this.$store.dispatch('wallet/req_getPrice');
    this.$store.dispatch('history/req_getMoneyActionsFilter');
  },
};
</script>

<style lang="scss" scoped>
.wallet {
  padding: 30px 50px;
  @media (max-width: 576px) {
    overflow-x: hidden;
  }

  @media (max-width: 992px) {
    padding: 30px 20px;
  }
  @media (max-width: 576px) {
    padding: 30px 10px;
  }
  .statistic-content {
    @media (max-width: 576px) {
      padding: 0px 10px;
      margin: 0px 0px 40px;
    }
  }

  .box-wallet {
    background: #081b3c;
    backdrop-filter: blur(15.5px);
    border-radius: 10px;
    padding: 20px;
    height: 100%;
    @media (max-width: 576px) {
      padding: 10px;
    }

    h3 {
      font-size: 25px;
      background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .wallet-tab {
      padding: 5px;
      background: rgba(27, 72, 187, 0.2);
      box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
      backdrop-filter: blur(34px);
      border-radius: 7px;
      width: fit-content;
      margin-bottom: 40px;
      overflow-x: auto;
      overflow-y: hidden;

      @media (max-width: 576px) {
        width: 100%;
      }

      .listTab {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .tab-item {
          padding: 0px 25px;
          height: 55px;
          @media (max-width: 1400px) {
            font-size: 14px;
            height: 35px;
          }
          @media (max-width: 576px) {
            min-width: 130px;
          }

          &.activeTab {
            background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
            border-radius: 6px;
          }
        }
      }
    }
  }
}
</style>